<template>
  <div>
    <div class="logo-small">
      <MainLogo class="logo" />
    </div>

    <el-row :gutter="50">
      
      <el-col :md="12" :xs="24" v-if="viewAsset">

        <div class="center">
          <img v-if="viewAsset.filetype == 'image'" :src="viewAsset.image_blob" class="artowork img-responsive" />

          <div v-if="viewAsset.filetype == 'video'">
            <video class="video-player" controls autoplay>
              <source :src="viewAsset.video_url">
            </video>
          </div>
        </div>

      </el-col>
     
      <el-col :md="12" :xs="24">
        <div class="details">

          <p>{{ viewAsset.created | formatDate('D MMMM, YYYY') }}</p>
          <h2 class="header2">{{ viewAsset.username }}</h2>

          <h5 class="header5">The story behind it</h5>
          <p>{{ viewAsset.story }}</p>

          <h5 class="header5">Makes me feel</h5>
          <div class="mood">
            <component :is="viewAsset.mood" class="accent-svg" /> {{ viewAsset.mood }}
          </div>


          <h5 class="header5">Place</h5>
          <p>{{ viewAsset.place }}</p>

          <div v-if="viewAsset.location">
            <GmapMap :options="mapOptions"
              ref="map"
              class="mini_map"
              :center="viewAsset.location"
              :zoom="10"
              map-type-id="terrain"  
            >
              <GmapMarker
                :position="viewAsset.location"
                :icon="{ url: require('@/assets/marker.png'), scaledSize: { width: 35, height: 50, f: 'px', b: 'px'}}"
              />
            </GmapMap>
          </div>

          <el-row>
            <el-col :md="12">
              <div class="social">
                  <h5 class="header5">Share</h5>
                  <ShareNetwork
                    network="email"
                    :url="`https://thevirtualquilt.com${this.$route.fullPath}`"
                    :title="`Check ${this.viewAsset.username}'s Artwork`"
                    description=" - The Virtual Quilt is a digital platform where you can upload images of your original artwork."
                    hashtags="thevirtualquilt,groovalution,elleeven,artwork"
                  >
                    <mail class="twitter" />
                  </ShareNetwork>
                  
                  <ShareNetwork
                    network="whatsapp"
                    :url="`https://thevirtualquilt.com${this.$route.fullPath}`"
                    :title="`Check ${this.viewAsset.username}'s Artwork`"
                    description=" - The Virtual Quilt is a digital platform where you can upload images of your original artwork."
                    hashtags="thevirtualquilt,groovalution,elleeven,artwork"
                  >
                    <whatsapp class="whatsapp" />
                  </ShareNetwork>
                </div>
                
            </el-col>

            <el-col :md="12" >
              <h5 class="header5">Rating</h5>

              <el-rate
                class="rating"
                v-model="viewAsset.rating"
                :icon-classes="['icomoon-heart-on', 'icomoon-heart-on', 'icomoon-heart-on']"
                void-icon-class="icomoon-heart-off"
                :colors="['#99A9BF', '#F7BA2A', '#94C01F']"
                @change="setRating"
                >
              </el-rate>

              <el-button type="primary" @click="sendRating">Vote</el-button>
            </el-col>

          </el-row>
        

        </div>
      </el-col>
    </el-row>

  </div> 
</template>

<script>
  import Vue from 'vue'
  import VueSocialSharing from 'vue-social-sharing'
  import { mapState, mapActions } from 'vuex'
  
  import MainLogo from '@/assets/Logo.svg';

  import facebook from "@/assets/social/facebook.svg"
  import twitter from "@/assets/social/twitter.svg"
  import mail from "@/assets/social/mail.svg"
  // import pinterest from "@/assets/social/pinterest.svg"
  import reddit from "@/assets/social/reddit.svg"
  import telegram from "@/assets/social/telegram.svg"
  import whatsapp from "@/assets/social/whatsapp.svg"

  import excited from "@/assets/mood/excited.svg"
  import happy from "@/assets/mood/happy.svg"
  import surprised from "@/assets/mood/surprised.svg"
  import bored from "@/assets/mood/bored.svg"
  import sad from "@/assets/mood/sad.svg"
  import angry from "@/assets/mood/angry.svg"

  /* VUE2-GOOGLE-MAPS
  ** https://www.npmjs.com/package/vue2-google-maps
  ** https://cloud.google.com/maps-platform/
  */
  import * as VueGoogleMaps from "vue2-google-maps"
  import mapStyles from '@/assets/mapStyles.json'

  Vue.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLEMAPS_API_KEY,
      libraries: 'places'
    }
  });

  Vue.use(VueSocialSharing);
  
  export default {

    components: {
      MainLogo,

      facebook,
      twitter,
      mail,
      // pinterest,
      reddit,
      telegram,
      whatsapp,

      excited,
      happy,
      surprised,
      bored,
      sad,
      angry
    },

    data() {
      return {
        mapOptions: {
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUI: true,
          draggable: false,
          styles: mapStyles
        },
        rating:0,
      }
    },

    computed: {
      ...mapState([
        'viewAsset'
      ])
    },

    methods: {

      ...mapActions([
        'rateAsset'
      ]),

      setRating: function(rating) {
        this.rating = rating;
      },

      sendRating: function() {
        this.rateAsset({ rating:this.rating, id:this.viewAsset.id })
        .then(() => {
          this.$notify({
            title: 'Thank you.',
            message: 'Your rating was submitted with success!',
            type: 'success'
          });
        })
      }
    }

  }
</script>